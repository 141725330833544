'use client'
import * as React from 'react'
import { Content } from "@prismicio/client";
import { linkResolver } from '../../prismicio'
import { SliceComponentProps, PrismicRichText } from "@prismicio/react";
import { PrismicNextImage, PrismicNextLink } from "@prismicio/next";
import { useIntersection } from 'react-use';
import Arrow from '@/assets/icons/right-arrow.svg'
import dynamic from 'next/dynamic'
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false })


/**
 * Props for `ShopAllPanel`.
 */
export type ShopAllPanelProps = SliceComponentProps<Content.ShopAllPanelSlice>;

/**
 * Component for "ShopAllPanel" Slices.
 */
const ShopAllPanel = ({ slice }: ShopAllPanelProps): JSX.Element => {

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isMobile, setIsMobile] = React.useState(false);

  const intersectionRef = React.useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  });

  const primary = slice.primary as any


  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Adjust the breakpoint for mobile size if needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  switch(slice.variation) {
    case 'default':
      return (
        <section
          data-slice-type={slice.slice_type}
          data-slice-variation={slice.variation}
          className="lg:px-[54px] mb-12 pr-5 md:pr-0"
          ref={intersectionRef}
        >
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            <div className="col-span-12 md:col-span-7 lg:col-span-8">
              {primary?.video?.url && !isMobile ? 
                <div className="object-cover object-center aspect-square md:aspect-video max-h-none md:max-h-">
                  <ReactPlayer url={primary.video.url} loop
                    playing={intersection && intersection.intersectionRatio < 0.5 ? false : true}
                    playsinline muted width="100%" height="100%"
                    className="small-video"/>
                </div>
                :
                <>
                  <PrismicNextImage field={slice.items[currentSlide].image} className="w-full object-cover object-center md:h-screen max-h-[600px] lg:max-h-[40vw] xxl:max-h-[30vw]" fallbackAlt=""/>
                  <div className="text-center relative w-full mt-2 mb-6 lg:mb-16">
                      {slice.items.length > 1 ?
                        <p className="inline-block text-[12px] leading-[18px]">
                          <Arrow className="inline-block mr-3 relative -top-[2px] cursor-pointer stroke-warmcharcoal" onClick={()=>setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 0)}/>
                          {currentSlide+1} / {slice.items?.length} 
                          <Arrow className="inline-block ml-3 rotate-180 relative -top-[2px] cursor-pointer stroke-warmcharcoal" onClick={()=>setCurrentSlide(currentSlide < slice.items.length-1 ? currentSlide+1 : slice.items.length-1)}/>
                        </p>
                      : 
                        <></>}
                  </div>
                </>}
            </div>
            <div className="col-span-12 md:col-span-5 lg:col-span-4 pt-2 relative px-5">
              <h2 className="text-[20px] leading-[32px] md:text-[24px] md:leading-[32px] font-sangbleu font-medium">{slice.primary.heading}</h2>
              <article className="text-[14px] leading-[18px] md:text-[16px] md:leading-[24px] font-norma mt-8 prose prose-p:mb-3">
                <PrismicRichText field={slice.primary.description} />
              </article>
              {slice.primary.button_link?.link_type !=="Any" ? 
                <PrismicNextLink field={slice.primary.button_link} linkResolver={linkResolver} className={(slice.items.length === 1 || primary.video?.url ? "lg:bottom-0" : "lg:bottom-16") + " relative lg:absolute my-8 lg:my-0 border-[1px] border-warmcharcoal p-2 px-4 font-norma text-[12px] leading-auto md:text-[14px] font-medium w-[190px] inline-block h-[40px]"}>{slice.primary.button_link_text} <Arrow className="rotate-180 inline-block absolute right-3 top-3 stroke-warmcharcoal"/></PrismicNextLink>
              :<></>}
            </div>
          </div>
        </section>
      );
    break;
    case 'textOnLeft':
      
      return (
        <section
          data-slice-type={slice.slice_type}
          data-slice-variation={slice.variation}
          className="md:px-5 lg:px-[54px] mb-24"
          ref={intersectionRef}
        >
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            <div className="col-span-12 md:col-span-5 lg:col-span-4 pt-2 relative px-5 md:px-0">
              <h2 className="text-[20px] leading-[32px] md:text-[24px] md:leading-[32px] font-sangbleu font-medium">{slice.primary.heading}</h2>
              <article className="text-[14px] leading-[18px] md:text-[16px] md:leading-[24px] font-norma mt-8 max-w-[90%] prose prose-p:mb-3">
                <PrismicRichText field={slice.primary.description} />
              </article>
              {slice.primary.button_link?.link_type!=="Any" ? 
              <PrismicNextLink field={slice.primary.button_link} linkResolver={linkResolver} className={(slice.items.length === 1 || primary.video?.url ? "lg:bottom-0" : "lg:bottom-24") + " relative lg:absolute my-8 lg:my-0 border-[1px] border-warmcharcoal p-2 px-4 font-norma text-[12px] leading-auto md:text-[14px] font-medium w-[190px] inline-block h-[40px]"}>{slice.primary.button_link_text} <Arrow className="rotate-180 inline-block absolute right-3 top-3 stroke-warmcharcoal"/></PrismicNextLink>
              :<></>}
            </div>
            <div className="col-span-12 md:col-span-7 lg:col-span-8 ">
            {primary?.video?.url && !isMobile ? 
                <div className="object-cover object-center overflow-hidden aspect-square md:aspect-video">
                  <ReactPlayer url={primary.video.url} loop
                    playing={intersection && intersection.intersectionRatio < 0.5 ? false : true}
                    playsinline muted width="100%" height="100%"
                    className="small-video"/>
                </div>
                :
                <>
                  <PrismicNextImage field={slice.items[currentSlide].image} className="w-full object-cover object-center md:max-h-[600px] lg:max-h-[40vw] xxl:max-h-[30vw]" fallbackAlt=""/>
                  <div className="text-center relative w-full mt-2 mb-6 lg:mb-16">
                      {slice.items.length > 1 ?
                        <p className="inline-block text-[12px] leading-[18px]">
                          <Arrow className="inline-block mr-3 relative -top-[2px] cursor-pointer stroke-warmcharcoal" onClick={()=>setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 0)}/>
                          {currentSlide+1} / {slice.items?.length} 
                          <Arrow className="inline-block ml-3 rotate-180 relative -top-[2px] cursor-pointer stroke-warmcharcoal" onClick={()=>setCurrentSlide(currentSlide < slice.items.length-1 ? currentSlide+1 : slice.items.length-1)}/>
                        </p>
                      : 
                        <></>}
                  </div>
                </>}
              </div>
            </div>
        </section>
      );
    break;
    default: return <></>
  }
  
};

export default ShopAllPanel;
