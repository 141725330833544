'use client'
import { useState } from 'react'
import { Content } from "@prismicio/client";
import { SliceComponentProps, PrismicRichText } from "@prismicio/react";
import { PrismicNextLink, PrismicNextImage } from '@prismicio/next'
import MinusCircleIcon from '@/assets/icons/minus-circle-icon.svg'
import PlusCircleIcon from '@/assets/icons/plus-circle-icon.svg'
/**
 * Props for `TextImage`.
 */
export type TextImageProps = SliceComponentProps<Content.TextImageSlice>;

/**
 * Component for "TextImage" Slices.
 */
const TextImage = ({ slice, context }: TextImageProps): JSX.Element => {

  const [openInfo, setOpenInfo] = useState(false);

  switch(slice.variation) {
    case 'default':
      return <></>
    break;
    case 'withImage':
      if(slice.primary.full_width === true) {
        return <PrismicNextImage field={slice.primary.image} fallbackAlt="" className="w-screen aspect-square md:aspect-video object-cover"/>
      } 
      return (
        <div className="grid grid-cols-12 gap-5">
            {slice.primary.image_position === true ? 
            <div className="col-span-12 md:col-span-3"></div>
            :<></>}
            <div className="col-span-12 md:col-span-9"><PrismicNextImage field={slice.primary.image} fallbackAlt="" className="w-full h-[50vh] md:h-[40vh] lg:h-[60vh] object-cover"/></div>
            {slice.primary.image_position !== true ? 
            <div className="col-span-12 md:col-span-3"></div>
            :<></>}
        </div>
      )
    break;
    case 'withImageAndMoreInfo':
      // console.log(slice.items)
      return (
        <section
          data-slice-type={slice.slice_type}
          data-slice-variation={slice.variation}
          className="p-5 md:p-0 md:pl-5 lg:pl-[54px] mb-10"
        >
          <div className="grid grid-cols-12 pb-6">
            <div className="col-span-12 md:col-span-6 lg:col-span-4 relative">
              <h2 className="font-sangbleu font-medium text-[24px] text-warmcharcoal mb-10">{slice.primary.heading}</h2>
              <article className="font-norma text-[16px] leading-[24px] max-w-[300px] mb-8 prose prose-p:mb-0">
                <PrismicRichText field={slice.primary.description} /> 
              </article>
              {slice.items[0] ? 
              <p className="hidden absolute -bottom-2 md:inline-block font-norma text-[14px] leading-[18px] cursor-pointer hover:opacity-60" onClick={()=>setOpenInfo(!openInfo)}>{slice.primary.more_info_text} {openInfo === true ? <MinusCircleIcon className="inline-block ml-[10px]"/> : <PlusCircleIcon className="inline-block ml-[10px]"/>}</p>
              : <></>}
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-8 lg:-ml-1">
              <PrismicNextImage field={slice.primary.image} fallbackAlt="" className="w-full h-[50vh] md:h-[37.5vw] object-cover"/>
            </div>
          </div>
          {slice.items[0] ?
          <p className="md:hidden inline-block font-norma text-[14px] leading-[18px] my-5 cursor-pointer hover:opacity-60" onClick={()=>setOpenInfo(!openInfo)}>{slice.primary.more_info_text} {openInfo === true ? <MinusCircleIcon className="inline-block ml-[10px]"/> : <PlusCircleIcon className="inline-block ml-[10px]"/>}</p>
          : <></>}
          {openInfo === true ? 
          <div className={(slice.items.length > 2 ? "max-w-full": "max-w-[1100px]") + " w-full mx-auto grid grid-cols-12 md:gap-10 pr-5 py-0 md:py-[100px] lg:pr-[54px]"}>
            {slice.items?.map((item: any, index: number)=>{
            return (
              <div className={slice.items.length === 2 ? "col-span-12 md:col-span-6" : slice.items.length === 3 ? "col-span-12 md:col-span-4" : slice.items.length === 4 ? "col-span-12 md:col-span-3" : slice.items.length > 4 ? "col-span-12 md:col-span-4" : "col-span-12"} key={index}>
                <article className="font-norma text-[16px] leading-[24px] prose prose-h6:font-medium prose-h6:mb-0 prose-h6:pb-0 prose-p:font-normal prose-p:mt-0 prose-p:pb-4 ">
                  <PrismicRichText field={item.more_info_column} /> 
                </article>            
              </div>
            )})}
          </div>
          : <></>}
        </section>
      );
    break;
    default: 
      return <></>
  }




};

export default TextImage;
